<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div class="myHeader" ref="myHeader_destinatarios">
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top">
            <headerBack @back="backLocal" />
          </div>
          <div class="destinatariosTitle title title_margin_top">
            {{ t("app.destinatarios.mis_destinatarios") }}
          </div>
        </div>
      </div>

      <blurComp
        class="blur_sup"
        :style="blur_sup_destinatarios"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado"
        :style="contenido_destinatarios"
        v-show="cont_visible"
        ref="contenido_destinatarios"
        @scroll="onScroll"
      >
        <div class="cont_300_basico ancho_acotado">
          <div class="destinatariosBody">
            <div v-if="destinatariosLista.length > 0">
              <div
                class="destinatariosRow"
                v-for="(destinatario, w) in destinatariosLista"
                :key="w"
              >
                <card-mis-datos-destinatario
                  :destinatario="destinatario"
                  :index="w"
                  @selectDestinatario="calldestinatarioDetalle"
                />
              </div>

            </div>

            <div v-else class="destinatarios_"  @click="callNewDestinatario">
              <div class="destinatarios_Img">
                <img :src="sin_destinatarios" alt="" srcset="" />
              </div>
              <div class="destinatarios_Tit">
                {{ t("app.cotizadorDestinatario.agrega_destinatario") }}
              </div>
              <div class="destinatarios_Text">
                {{ t("app.cotizadorDestinatario.facil_agrega_dest") }}
              </div>

            </div>
          </div>
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_destinatarios"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer_estrecho_centrado" ref="footer_destinatarios">
        <div class="footer_estrecho">
          <div
            class="destinatarios_NuevoDest_otro agregar_otro_dest_cont"
            @click="callNewDestinatario"
          >
            <div class="destinatarios_NuevoDestText_otro">
              {{ t("app.cotizadorDestinatario.NewDestinatario") }}
            </div>
            <div class="destinatarios_NuevoDestBtn_otro">
              <img :src="iconCotiNuevoDestinatario" alt="" srcset="" />
            </div>
          </div>
          <div class="destinatariosFooter">
            <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import headerBack from "../components/generales/headerBack.vue";
import { menuController, modalController, alertController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import { mapActions, mapMutations, mapState } from "vuex";
import cardMisDatosDestinatario from "../components/destinatario/cardMisDatosDestinatario.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },

  name: "destinatarios",
  components: {
    headerBack,
    cardMisDatosDestinatario,
    enviosSeguros,
    screen_width_cont,
    blurComp,
  },
  data() {
    return {
      cont_visible: false,
      header: 0,
      footer: 0,
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_destinatarios: {},
      blur_sup_destinatarios: {},
      blur_inf_destinatarios: {},
      sin_destinatarios: require("../assets/imgs/cotizador/sin_destinatarios.svg"),
      iconCotiNuevoDestinatario: require("../assets/imgs/iconCotiNuevoDestinatario.svg"),
    };
  },
  computed: {
    ...mapState([
      "menuSpDesde",
      "remitente",
      "desde",
      "nav_stack",
      "widthScreen",
      "heigthScreen",
      "plataforma",
    ]),

    destinatariosLista() {
      let aux = [];
      if (this.remitente && this.remitente.destinatarios) {
        let destinatariosIdArray = Object.keys(this.remitente.destinatarios);
        destinatariosIdArray.forEach((destinatario) => {
          if (destinatario != "0") {
            aux.push(this.remitente.destinatarios[destinatario]);
          }
        });
      }
      if (aux.length > 0) {
        aux.sort((a, b) => {
          return Intl.Collator().compare(a.nombre1, b.nombre1);
        });
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateProcesoGeneral",
      "updateBloquearNavegacion",
      "updateDataDestinatarioSelected",
      "set_nav_stack",
      "setshowSpinner",
      "updateTransaccion",
      "updateDesde",
      "updateMenuSpDesde",
    ]),
    ...mapActions(["traer_tipo_doc_v2"]),

    backLocal() {
      if (this.nav_stack.menuSp) {
        menuController.open("firstMenu");
      }
      let aux = [...this.nav_stack.desde];
      let destino = aux.pop();
      this.set_nav_stack({ desde: aux, menuSp: false });
      this.$router.push({ name: destino, replace: true });
    },
    async calldestinatarioDetalle(index) {
      this.presentAlert(index);
    },

    async presentAlert(index) {
      let alert = await alertController.create({
        header: "",
        subHeader: this.t("app.destinatarios.que_desea_hacer"),
        mode: "ios",
        cssClass: "custom-alert",
        buttons: [
          {
            text: this.t("app.destinatarios.ver_detalle"),
            role: "confirm",
            handler: async () => {
              this.setshowSpinner(true);
              this.updateDataDestinatarioSelected(
                this.destinatariosLista[index]
              );

              await this.traer_tipo_doc_v2({
                pais: this.destinatariosLista[
                  index
                ].pais_moneda_destino_id.split("_")[0],
              });

              this.setshowSpinner(false);
              this.$router.push({
                name: "destinatariosDetalle",
                replace: true,
              });
              // console.log(index);
            },
          },
          {
            text: this.t("app.destinatarios.enviar_dinero"),
            role: "confirm",
            handler: () => {
              // console.log("Enviar dinero");

              this.updateTransaccion({
                id_persona: this.destinatariosLista[index].id,
                pais_destino:
                  this.destinatariosLista[index].pais_moneda_destino_id,
              });

              this.$router.push({
                name: "ingresar_giro",
                replace: true,
              });
            },
          },
        ],
        backdropDismiss: true,
      });
      await alert.present();
    },

    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
    async callNewDestinatario() {
      this.$router.push({ name: "nuevoDestinatarioDisclaimer" });
    },
  },
  watch: {
    heigthScreen() {
      this.contenido_destinatarios = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
  },
  mounted() {},
  ionViewWillEnter() {
    this.updateProcesoGeneral("misDatos");
    this.updateDesde("destinatarios");
    this.updateMenuSpDesde({ desde: "destinatarios", menuSp: false });
  },
  ionViewDidEnter() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_destinatarios"].offsetHeight;
        this.footer = this.$refs["footer_destinatarios"].offsetHeight;
        this.cont_visible = true;
        this.contenido_destinatarios = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_destinatarios = blur_sup_style(this.header);
        this.blur_inf_destinatarios = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_destinatarios"].scrollHeight >
          this.$refs["contenido_destinatarios"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
};
</script>

<style>
.destinatariosTitle {
  color: #801b1c;
}
.destinatariosBody {
  overflow-y: auto;
}
.destinatariosRow {
  margin-top: 1rem;
}
.destinatariosFooter {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}
.destinatarios_ {
  background: linear-gradient(180deg, #f3f3f3 0%, #ffffff 100%);
  border-radius: 33px;
  cursor: pointer;
}
.destinatarios_Img {
  padding-top: 2.2rem;
  display: flex;
  justify-content: center;
}
.destinatarios_Img img {
  width: 4rem;
}
.destinatarios_Tit {
  margin-top: 1rem;
  padding: 0 3rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1px;
  color: #614ad3;
}
.destinatarios_Text {
  margin-top: 0.875rem;
  padding: 0 3rem 2rem 3rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 1px;
  color: #787878;
}


ion-alert.custom-alert {
  --background: #e3edff;
}
.alert-button.sc-ion-alert-ios {
  color: #614ad3;
}
.alert-button.sc-ion-alert-ios:last-child {
  font-weight: inherit;
}
.custom-alert .alert-wrapper {
  background: white;
}

.agregar_otro_dest_cont {
  padding-top: 3rem;
}
.destinatarios_NuevoDest_otro {
  margin-top: 0.3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  cursor: pointer;
  padding-bottom: 2rem;
}
.destinatarios_NuevoDestText_otro {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #614ad3;
}
.destinatarios_NuevoDestBtn_otro {
  /* width: 3.4375rem;
  padding-left: 1rem; */
  width: fit-content;
  margin-left: 2rem;
}
</style>