<template>
  <div
    class="cardMisDatosDestContainer clickable"
    @click="selectDestinatario"
    ref="cardMisDatosDestContainer"
  >
    <div class="cardMisDatosDestItemIcon">{{ inicial }}</div>
    <div class="cardMisDatosDestItemInfoNombre" :style="center_style">
      <div>{{ nombre }} &nbsp;</div>
    </div>
    <div class="cardMisDatosDestItemInfo_flag">
      <country-flag class="" :country="bandera" />
    </div>
    <!-- <div class="cardMisDatosDestEdit">
      <editar_eliminar
        type="editar"
        formato="icon"
        ancho_img="0.8"
        font_size_text="10"
      />
    </div> -->
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
// import editar_eliminar from "../generales/editar_eliminar.vue";
import CountryFlag from "vue-country-flag-next";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    destinatario: Object,
    index: Number,
  },
  components: {
    // editar_eliminar,
    CountryFlag,
  },
  data() {
    return {
      zona_centro: 0,
    };
  },
  computed: {
    inicial() {
      let aux = "";
      if (this.destinatario) {
        aux = this.destinatario.nombre1.substring(0, 1);
      }
      return aux;
    },
    nombre() {
      let aux = "";
      if (this.destinatario) {
        aux = `${this.destinatario.nombre1} ${this.destinatario.apellido1} ${this.destinatario.apellido2}`;
      }
      return aux;
    },
    bandera() {
      let aux = "";
      let aux2 = this.destinatario.pais_moneda_destino_id.split("_")[0];
      if (aux2 == "68") {
        aux = "bo";
      } else if (aux2 == "99") {
        aux = "do";
      } else if (aux2 == "170") {
        aux = "co";
      } else if (aux2 == "218") {
        aux = "ec";
      } else if (aux2 == "332") {
        aux = "ht";
      } else if (aux2 == "400") {
        aux = "cn";
      } else if (aux2 == "450") {
        aux = "es";
      } else if (aux2 == "604") {
        aux = "pe";
      } else if (aux2 == "800") {
        aux = "br";
      } else if (aux2 == "900") {
        aux = "ar";
      }
      return aux;
    },
    center_style() {
      let aux = {
        width: `0px`,
      };

      aux = {
        // width: `${this.zona_centro - 106}px`
        width: `${this.zona_centro - 30}px`,
      };

      return aux;
    },
  },
  methods: {
    selectDestinatario() {
      this.$emit("selectDestinatario", this.index);
    },
  },
  mounted() {
    let centro = this.$refs["cardMisDatosDestContainer"];
    this.zona_centro = centro.offsetWidth;

    setTimeout(() => {
      this.zona_centro = centro.offsetWidth;
    }, 200);
  },
};
</script>

<style>
.cardMisDatosDestContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid #ececec;
}
.cardMisDatosDestItemIcon {
  width: 2.3rem;
  height: 2.3rem;
  min-width: 2.3rem;

  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  padding: 0 0 0 0.1rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c4c4c4;
}
.cardMisDatosDestItemInfoNombre {
  padding-left: 0.9rem;
  padding-right: 0.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  width: 100%;
  display: inline-flex;

  color: #801b1c;
}

.cardMisDatosDestEdit {
  padding-right: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0;
}
.cardMisDatosDestItemInfo_flag {
  width: 3.5rem;
}
.cardMisDatosDestItemInfo_flag .normal-flag{
    margin: 0em -0.9em -0.6em -0.7em;
  }

/* ////////////////-------------------------------------/////////// */
@media (min-width: 450px) {
  .cardMisDatosDestItemInfoNombre {
    font-size: 0.9rem;
    line-height: 1.0rem;
  }
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px) {
  .cardMisDatosDestItemIcon {
    width: 2.3rem;
    height: 2.3rem;
    min-width: 2.3rem;
    font-size: 20px;
    line-height: 23px;
  }
  .cardMisDatosDestItemInfoNombre {
    font-size: 1rem;
    line-height: 1.1rem;
  }
  
}
</style>